import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"

import { CopyToClipboard } from "react-copy-to-clipboard"
import { setTimeout } from "timers"

import arcadeGIF1 from "../images/presskit/arcade-gif-2.gif"
import arcadeGIF2 from "../images/presskit/arcade-gif-1.gif"
import editorGIF1 from "../images/presskit/editor-gif-1.gif"

import "../css/pages/press.scss"

var tmpCallback

class Press extends React.Component {
  state = {
    value: "",
    copied: false,
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.value !== prevState.value) {
      console.log("copied")
      this.setState({
        copied: true,
      })
      clearTimeout(tmpCallback)
      tmpCallback = setTimeout(() => {
        this.setState({
          copied: false,
        })
      }, 2000)
    }
  }

  render() {
    return (
      <Layout>
        <SEO title="Press" />
        <div className="press">
          <div
            className={
              "copy-notification " + (this.state.copied ? "show" : "hide")
            }
          >
            <span>Copied: {this.state.value}</span>
          </div>

          <h1>Project Arrhythmia Factsheet</h1>
          <div className="factsheet">
            <div className="left">
              <h3>Publisher</h3>
              <p>
                <a href="http://tophat.studio/">Top Hat Studios Inc</a>, based
                in New York
              </p>

              <h3>Developer</h3>
              <p>Vitamin Games LLC, based in Texas</p>

              <h3>Release Date</h3>
              <p>June 14th 2019</p>

              <h3>Platforms</h3>
              <p>Windows PC (Steam)</p>
              <p>...</p>

              <h3>Website</h3>
              <p>
                <a href="http://projectarrhythmia.com">projectarrhythmia.com</a>
              </p>

              <h3>Price</h3>
              <p>USD $11.99</p>
            </div>
            <div className="right">
              <h3 className="first">Description</h3>
              <p>
                "Project Arrhythmia" is a musical bullet-hell in which all
                things beautiful are deadly. A cacophony of lights, music,
                pulses and blocks, Project Arrhythmia features tight bullet-hell
                style gameplay where up to 4 players in local co-op must
                navigate their way through evolving scenes and obstacles, all
                timed to the beat.
              </p>
              <h3>Current Features</h3>
              <ul>
                <li>
                  Dodge The Beat: Stages consist of mesmerizing, musically timed
                  scenes, each one telling a story. Feel the rhythm and feel
                  your way around the hazards! Anna will judge how you do.
                </li>
                <li>
                  Challenge Your Friends: Up to 4 players supported in local
                  co-op mode.
                </li>
                <li>
                  Robust Level Editor: Carefully designed level editor allows
                  users to create their own levels and challenges, to whatever
                  music they wish.
                </li>
                <li>
                  Huge Community: Wealth of user created content exists, fully
                  integrated into the game through the usage of the level editor
                  and user made levels.
                </li>
              </ul>
              <h3>Upcoming Features</h3>
              <ul>
                <li>
                  Story Mode: Over 20+ hand crafted heart pounding levels
                  interwoven with an engaging story.
                </li>
                <li>
                  More Co-Op Game Modes: Party (endless level selection with
                  friends) / Snake (collect pellets that gradually make you
                  larger) / Battle Royale (last to die wins)
                </li>
                <li>
                  Full Community Integration: Voting system for levels, better
                  search, straight importing of levels and more
                </li>
                <li>Cross-Platform Modding Support</li>
              </ul>
              <h3>Story</h3>
              <p>
                Set in 205X, a mysterious disease called the “Tokyo Flu” has
                been ravaging the world. A lone computer programmer, Anna
                Schroeder, attempts to find the cure with her trusty
                supercomputer-companion HAL. Stumbling upon various different
                “Projects”, the duo decide to investigate the efficacy of a cure
                through the usage of a pulse and rhythm based interface.
              </p>
            </div>
          </div>

          <h1>Screenshots</h1>
          <div className="screenshots">
            <h3>Arcade</h3>
            <div className="arcade">
              <CopyToClipboard
                text={
                  "https://github.com/vitamin-games/vitamin-games-website/raw/master/src/images/presskit/arcade-gif-2.gif"
                }
                onCopy={() => this.setState({ value: "arcade-gif-2.gif" })}
              >
                <img className="gif bottom" src={arcadeGIF1} />
              </CopyToClipboard>
              <CopyToClipboard
                text={
                  "https://github.com/vitamin-games/vitamin-games-website/raw/master/src/images/presskit/arcade-gif-1.gif"
                }
                onCopy={() => this.setState({ value: "arcade-gif-1.gif" })}
              >
                <img className="gif" src={arcadeGIF2} />
              </CopyToClipboard>
              <CopyToClipboard
                text={
                  "https://github.com/vitamin-games/vitamin-games-website/raw/master/src/images/presskit/arcade (1).png"
                }
                onCopy={() => this.setState({ value: "arcade (1).png" })}
              >
                <div className="button">
                  <Image filename={"presskit/arcade (1).png"} />
                </div>
              </CopyToClipboard>
              <CopyToClipboard
                text={
                  "https://github.com/vitamin-games/vitamin-games-website/raw/master/src/images/presskit/arcade (7).png"
                }
                onCopy={() => this.setState({ value: "arcade (7).png" })}
              >
                <div className="button">
                  <Image filename={"presskit/arcade (7).png"} />
                </div>
              </CopyToClipboard>
              <CopyToClipboard
                text={
                  "https://github.com/vitamin-games/vitamin-games-website/raw/master/src/images/presskit/arcade (3).png"
                }
                onCopy={() => this.setState({ value: "arcade (3).png" })}
              >
                <div className="button">
                  <Image filename={"presskit/arcade (3).png"} />
                </div>
              </CopyToClipboard>
              <CopyToClipboard
                text={
                  "https://github.com/vitamin-games/vitamin-games-website/raw/master/src/images/presskit/arcade (4).png"
                }
                onCopy={() => this.setState({ value: "arcade (4).png" })}
              >
                <div className="button">
                  <Image filename={"presskit/arcade (4).png"} />
                </div>
              </CopyToClipboard>
              <CopyToClipboard
                text={
                  "https://github.com/vitamin-games/vitamin-games-website/raw/master/src/images/presskit/arcade (5).png"
                }
                onCopy={() => this.setState({ value: "arcade (5).png" })}
              >
                <div className="button">
                  <Image filename={"presskit/arcade (5).png"} />
                </div>
              </CopyToClipboard>
              <CopyToClipboard
                text={
                  "https://github.com/vitamin-games/vitamin-games-website/raw/master/src/images/presskit/arcade (6).png"
                }
                onCopy={() => this.setState({ value: "arcade (6).png" })}
              >
                <div className="button">
                  <Image filename={"presskit/arcade (6).png"} />
                </div>
              </CopyToClipboard>
              <CopyToClipboard
                text={
                  "https://github.com/vitamin-games/vitamin-games-website/raw/master/src/images/presskit/arcade (2).png"
                }
                onCopy={() => this.setState({ value: "arcade (2).png" })}
              >
                <div className="button">
                  <Image filename={"presskit/arcade (2).png"} />
                </div>
              </CopyToClipboard>
              <CopyToClipboard
                text={
                  "https://github.com/vitamin-games/vitamin-games-website/raw/master/src/images/presskit/arcade (8).png"
                }
                onCopy={() => this.setState({ value: "arcade (8).png" })}
              >
                <div className="button">
                  <Image filename={"presskit/arcade (8).png"} />
                </div>
              </CopyToClipboard>
            </div>
            <h3>Editor</h3>
            <div className="editor">
              <CopyToClipboard
                text={
                  "https://github.com/vitamin-games/vitamin-games-website/raw/master/src/images/presskit/editor-gif-1.gif"
                }
                onCopy={() => this.setState({ value: "editor-gif-1.gif" })}
              >
                <img className="gif" src={editorGIF1} />
              </CopyToClipboard>
              <CopyToClipboard
                text={
                  "https://github.com/vitamin-games/vitamin-games-website/raw/master/src/images/presskit/editor (1).png"
                }
                onCopy={() => this.setState({ value: "editor (1).png" })}
              >
                <div className="button">
                  <Image filename={"presskit/editor (1).png"} />
                </div>
              </CopyToClipboard>
              <CopyToClipboard
                text={
                  "https://github.com/vitamin-games/vitamin-games-website/raw/master/src/images/presskit/editor (2).png"
                }
                onCopy={() => this.setState({ value: "editor (2).png" })}
              >
                <div className="button">
                  <Image filename={"presskit/editor (2).png"} />
                </div>
              </CopyToClipboard>
              <CopyToClipboard
                text={
                  "https://github.com/vitamin-games/vitamin-games-website/raw/master/src/images/presskit/editor (3).png"
                }
                onCopy={() => this.setState({ value: "editor (3).png" })}
              >
                <div className="button">
                  <Image filename={"presskit/editor (3).png"} />
                </div>
              </CopyToClipboard>
              <CopyToClipboard
                text={
                  "https://github.com/vitamin-games/vitamin-games-website/raw/master/src/images/presskit/editor (4).png"
                }
                onCopy={() => this.setState({ value: "editor (4).png" })}
              >
                <div className="button">
                  <Image filename={"presskit/editor (4).png"} />
                </div>
              </CopyToClipboard>
            </div>
          </div>

          <h1>Graphics</h1>
          <div className="screenshots">
            <h3>Logos</h3>
            <div className="logos pa">
              <CopyToClipboard
                text={
                  "https://github.com/vitamin-games/vitamin-games-website/raw/master/src/images/presskit/pa_logo.png"
                }
                onCopy={() => this.setState({ value: "pa_logo.png" })}
              >
                <div className="button">
                  <Image
                    className="bg-white padding"
                    filename={"presskit/pa_logo.png"}
                  />
                </div>
              </CopyToClipboard>
              <CopyToClipboard
                text={
                  "https://github.com/vitamin-games/vitamin-games-website/raw/master/src/images/presskit/pa_logo_only.png"
                }
                onCopy={() => this.setState({ value: "pa_logo_only.png" })}
              >
                <div className="button">
                  <Image
                    className="bg-white"
                    filename={"presskit/pa_logo_only.png"}
                  />
                </div>
              </CopyToClipboard>
            </div>
            <h3>Colors</h3>
            <div className="colors">
              <CopyToClipboard
                text={"#ef756c"}
                onCopy={() => this.setState({ value: "#ef756c" })}
              >
                <div className="light-red">
                  <span>Light Red</span>
                  <span>#ef756c</span>
                </div>
              </CopyToClipboard>
              <CopyToClipboard
                text={"#f05455"}
                onCopy={() => this.setState({ value: "#f05455" })}
              >
                <div className="red">
                  <span>Red</span>
                  <span>#f05455</span>
                </div>
              </CopyToClipboard>
              <CopyToClipboard
                text={"#f2f2f2"}
                onCopy={() => this.setState({ value: "#f2f2f2" })}
              >
                <div className="light-white">
                  <span>Almost White</span>
                  <span>#f2f2f2</span>
                </div>
              </CopyToClipboard>
              <CopyToClipboard
                text={"#f3f3f4"}
                onCopy={() => this.setState({ value: "#f3f3f4" })}
              >
                <div className="white">
                  <span>Nearly White</span>
                  <span>#f3f3f4</span>
                </div>
              </CopyToClipboard>
              <CopyToClipboard
                text={"#323233"}
                onCopy={() => this.setState({ value: "#323233" })}
              >
                <div className="grey">
                  <span>Clearly Grey</span>
                  <span>#323233</span>
                </div>
              </CopyToClipboard>
              <CopyToClipboard
                text={"#111111"}
                onCopy={() => this.setState({ value: "#111111" })}
              >
                <div className="black">
                  <span>Nearly Black</span>
                  <span>#111111</span>
                </div>
              </CopyToClipboard>
            </div>
          </div>

          <h1>Vitamin Games Factsheet</h1>
          <div className="factsheet">
            <div className="left">
              <h3>Location</h3>
              <p>Austin, Texas</p>

              <h3>Formed</h3>
              <p>2019</p>

              <h3>Employees</h3>
              <p>
                <a href="https://twitter.com/vg_pidge">Pidge</a>
              </p>

              <h3>Website</h3>
              <p>
                <a href="http://vitamin.games">vitamin.games</a>
              </p>
            </div>
            <div className="right">
              <h3 className="first">Description</h3>
              <p>
                Vitamin Games started as an independent game development and
                design studio back in 2009 by Pidge. Vitamin Games'
                current game development project is called Project Arrhythmia, a
                rhythmic dodge-em up.
              </p>
              <h3>Projects</h3>
              <ul>
                <li>Project Arrhythmia</li>
                <li>Takoyaki Run</li>
                <li>VR Coding</li>
              </ul>
            </div>
          </div>

          <h1>Graphics</h1>
          <div className="screenshots">
            <h3>Logos</h3>
            <div className="logos">
              <CopyToClipboard
                text={
                  "https://github.com/vitamin-games/vitamin-games-website/raw/master/src/images/presskit/logos (2).png"
                }
                onCopy={() => this.setState({ value: "logos (2).png" })}
              >
                <div className="button">
                  <Image
                    className="bg-white"
                    filename={"presskit/logos (2).png"}
                  />
                </div>
              </CopyToClipboard>
              <CopyToClipboard
                text={
                  "https://github.com/vitamin-games/vitamin-games-website/raw/master/src/images/presskit/logos (5).png"
                }
                onCopy={() => this.setState({ value: "logos (5).png" })}
              >
                <div className="button">
                  <Image
                    className="bg-purple"
                    filename={"presskit/logos (5).png"}
                  />
                </div>
              </CopyToClipboard>
              <CopyToClipboard
                text={
                  "https://github.com/vitamin-games/vitamin-games-website/raw/master/src/images/presskit/logos (3).png"
                }
                onCopy={() => this.setState({ value: "logos (3).png" })}
              >
                <div className="button">
                  <Image
                    className="bg-white"
                    filename={"presskit/logos (3).png"}
                  />
                </div>
              </CopyToClipboard>
              <CopyToClipboard
                text={
                  "https://github.com/vitamin-games/vitamin-games-website/raw/master/src/images/presskit/logos (4).png"
                }
                onCopy={() => this.setState({ value: "logos (4).png" })}
              >
                <div className="button">
                  <Image
                    className="bg-white"
                    filename={"presskit/logos (4).png"}
                  />
                </div>
              </CopyToClipboard>
              <CopyToClipboard
                text={
                  "https://github.com/vitamin-games/vitamin-games-website/raw/master/src/images/presskit/logos (1).png"
                }
                onCopy={() => this.setState({ value: "logos (1).png" })}
              >
                <div className="button">
                  <Image
                    className="bg-white"
                    filename={"presskit/logos (1).png"}
                  />
                </div>
              </CopyToClipboard>
            </div>
            <h3>Colors</h3>
            <div className="colors">
              <CopyToClipboard
                text={"#41436b"}
                onCopy={() => this.setState({ value: "#41436b" })}
              >
                <div className="purple">
                  <span>Perfectly Purple</span>
                  <span>#41436b</span>
                </div>
              </CopyToClipboard>
              <CopyToClipboard
                text={"#ef4768"}
                onCopy={() => this.setState({ value: "#ef4768" })}
              >
                <div className="pink">
                  <span>Poppin' Pink</span>
                  <span>#ef4768</span>
                </div>
              </CopyToClipboard>
              <CopyToClipboard
                text={"#00aeef"}
                onCopy={() => this.setState({ value: "#00aeef" })}
              >
                <div className="blue">
                  <span>Neon Blue</span>
                  <span>#00aeef</span>
                </div>
              </CopyToClipboard>
              <CopyToClipboard
                text={"#f3f3f4"}
                onCopy={() => this.setState({ value: "#f3f3f4" })}
              >
                <div className="white">
                  <span>Nearly White</span>
                  <span>#f3f3f4</span>
                </div>
              </CopyToClipboard>
              <CopyToClipboard
                text={"#323233"}
                onCopy={() => this.setState({ value: "#323233" })}
              >
                <div className="grey">
                  <span>Clearly Grey</span>
                  <span>#323233</span>
                </div>
              </CopyToClipboard>
              <CopyToClipboard
                text={"#111111"}
                onCopy={() => this.setState({ value: "#111111" })}
              >
                <div className="black">
                  <span>Nearly Black</span>
                  <span>#111111</span>
                </div>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Press
